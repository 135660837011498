import "../styles/Experience.scss";

import sunProUSAImage from "../images/icons/sunpro.png";

function Experience() {
    return (
        <div className={"Experience"}>
            <div className={"Card"}>
                <h2>Sun-Pro USA, <span id={"sun-pro-internship"}>Internship/Consultant</span></h2>
                <div className={"DescriptionDiv"}>
                    <div className={"Left"}>
                        <p>
                            Assisted the company on implementing and upgrading hardware. Most notably; I fixed a software
                            bug that would cause a heavy-spike in CPU usage, resulting in slower performance. That bug-fix
                            resulted in a reduced end-to-end acknowledgement time of around 3 seconds.
                        </p>

                        <div className={"ExperienceInformation"}>
                            <div className="location">
                                <h2>Location</h2>
                                <p>Sacramento, CA</p>
                            </div>

                            <div className="start-date">
                                <h2>Start Date</h2>
                                <p>July 2022</p>
                            </div>

                            <div className="end-date">
                                <h2>End Date</h2>
                                <p>June 2024</p>
                            </div>
                        </div>

                    </div>
                    <div className={"Right"}>
                        <img src={sunProUSAImage} alt="sun pro usa image"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Experience;
