import "../styles/SocialMedia.scss";

import githubImage from "../images/icons/github.png";
import instagramImage from "../images/icons/instagram.png";
import linkedInImage from "../images/icons/linkedin.png";

function SocialMedia() {
    return (
        <div className={"SocialMediaCards"}>
            <a href="https://www.github.com/beanbeanjuice" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>GitHub</h3>
                <img src={githubImage} alt="Github Icon"/>
            </a>

            <a href="https://www.linkedin.com/in/orloppw" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>LinkedIn</h3>
                <img src={linkedInImage} alt="LinkedIn Icon"/>
            </a>

            <a href="https://www.instagram.com/orloppw" target="_blank" rel={"noreferrer"} className={"Card"}>
                <h3>Instagram</h3>
                <img src={instagramImage} alt="Instagram Icon"/>
            </a>
        </div>
    );
}

export default SocialMedia;
